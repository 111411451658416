<template>
	<div>
		<Header :title="isEdit ? '修改攻略' : '新增攻略'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

				<a-form-item label="攻略标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.title" placeholder="请输入攻略标题"></a-input>
				</a-form-item>

				<a-form-item label="是否热门攻略" name="isHot" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group v-model:value="modelRef.isHot">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="封面图片" name="img" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
					<a-upload v-model:file-list="fileList" list-type="picture-card" action="/admin/ajaxUpload.do"
						accept="image/*" @change="onUploadChange">
						<div v-if="fileList.length < 1">
							<Icon icon="PlusOutlined"></Icon>
							<div style="margin-top: 8px">Upload</div>
						</div>
					</a-upload>
				</a-form-item>

				<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
					<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
				</a-form-item>
				
				<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="攻略内容">
					<div style="position: relative;z-index: 2;">
						<Editor v-model:value="modelRef.content" :configSetting="{ disabled: isSee }"></Editor>
					</div>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getPerformStrategyDetail, savePerformStrategy, updatePerformStrategy } from '@/service/modules/perform.js';
	export default {
		components: {
			Header,
			Icon,
			Editor,
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				fileList: [],
				loading: false,
				modelRef: {
					isHot: 0,
					isDisabled: 0
				},
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				let ret;
				this.loading = true;
				if (this.isEdit && this.id) {
					ret = await updatePerformStrategy(postData);
				} else {
					ret = await savePerformStrategy(postData)
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！');
					this.onBack(true);
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformStrategyDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.fileList = [{
						uid: 0,
						name: ret.data.img,
						url: ret.data.img
					}];
					this.modelRef = ret.data;
				}
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.img = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			}
		}
	}
</script>

<style scoped>

</style>